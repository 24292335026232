import React, { useEffect, useState } from 'react';
import { firebaseRoot } from '../services/firebase';
import { Pos } from '../types/pos';
import { Store, StoreByClientName, StoreByCompanyAndLocationId } from '../models/store';

interface IOrderListProps {
    store: Store;
}
interface IncomingOrder {
    id: string;
    firstName: string;
    orderId: string;
    isWebOrder:boolean;
    orderDate: number;
    isNew: boolean;
    transactionId: string;
    isDelivery?: string;
}

const KNOWN_ITEMS_KEY = 'io.budguide.notifications.known-items';
const TWO_HOURS = 3600 * 2;
const ONE_HOUR = 3600;
const ONE_MINUTE = 60;
const MAX_AGE_HOURS = 3;

function OrderList(prop: IOrderListProps) {
    const [audio] = useState(new Audio('/alert.wav'));
    const [orders, setOrders]  = useState<IncomingOrder[]>([]);
    const [isNewOrders, setIsNewOrders] = useState<boolean>(false);

    const { store } = prop;
    const rootPath = (store as StoreByClientName)?.getRootPath() ?? (store as StoreByCompanyAndLocationId)?.getRootPath() ?? '';
    const ordersRef = firebaseRoot.child(rootPath);
    
    useEffect(() => {
        console.log('listening is ON');
        console.log('childPath: ', rootPath);
        
        ordersRef.on('value', (snapshot: any) => {
            // populate a list of item Ids we've already seen
            let oldItemIdsString = localStorage.getItem(KNOWN_ITEMS_KEY);
            let oldItemIds: string[] = [];
            if (oldItemIdsString?.length) {
                oldItemIds = oldItemIdsString.split(',');
            }

            let items = snapshot.val();
            let newState: IncomingOrder[] = [];

            // populate new orders
            for (let item in items) {
                const rawItem = items[item];
                const isOld: boolean = oldItemIds.includes(item);

                let orderIdString = rawItem.orderId != null ? `BudGuide #${rawItem.orderId}` : null;
                let orderIDString = rawItem.orderID != null ? `BudGuide #${rawItem.orderID}` : null;

                let emailAddress = rawItem.emailAddress ?? rawItem.email;
                let phoneNumber = rawItem.phone;
                let combinedEmailPhone = `${emailAddress ?? ''} ${phoneNumber ?? ''}`.trim();
                combinedEmailPhone = combinedEmailPhone != '' ? `(${combinedEmailPhone})` : '';

                let name = rawItem.custName ?? rawItem.customerName;
                let combinedNameEmailPhone = name != null ? `${name} ${combinedEmailPhone}`.trim() : null;

                const newOrder: IncomingOrder = {
                    id: item,
                    firstName: rawItem.FirstName ?? combinedNameEmailPhone ?? ''.replace('(', '').replace(')', ''),
                    orderId: orderIdString ?? rawItem.LastName ?? orderIDString ?? '',
                    isWebOrder: rawItem['is_web_order'],
                    orderDate: Date.parse(rawItem['orderDate']) || 0,
                    isNew: !isOld,
                    transactionId: rawItem.transactionId,
                    isDelivery: rawItem.isDelivery ?? false,
                };

                newState.push(newOrder);
            }
            
            // truncate and filter if we're too long.
            if (newState.length > 4) {
                newState = newState
                    .filter((n) => {
                        // filter out orders with no orderDate
                        if (n.orderDate === 0) {
                            return false;
                        }

                        // filter out orders older than 2 hours
                        const nowInMillis = Date.now();
                        const delta = nowInMillis - n.orderDate;
                        const deltaInSeconds = delta / 1000;
                        const deltaInHours = deltaInSeconds / ONE_HOUR;
                        return deltaInHours <= MAX_AGE_HOURS;
                    })
                    .sort((a, b) => b.orderDate - a.orderDate); // orderDate descending
                    // .slice(0, 6); // first six records
            }

            // if there are any orders that are new, let's inform the UI and play a sound
            if(newState.length > 0 && newState.some((n: IncomingOrder) => n.isNew )) {
                setIsNewOrders(true); // only set to true if there were orders before
                audio.play().catch((e: any) => console.info('No playing mechanism.', e));
            }

            setOrders(newState);
        });

        // Cleanup
        return () => {
            console.log('Cleaning up...');

            // remove know item ids
            localStorage.removeItem(KNOWN_ITEMS_KEY);

            // turn off listening to database changes
            console.log('listening is OFF');
            ordersRef.off();
        };
    }, [audio, prop.store]);

    const _relativeTime = (timestamp: number): string => {
        if (timestamp === 0) {
            return 'Time unknown';
        }

        const nowInMillis = Date.now();
        const delta = nowInMillis - timestamp;
        const deltaInSeconds = delta / 1000;

        if (deltaInSeconds > TWO_HOURS) {
            // it's preetty old
            const timeAgoInHours = deltaInSeconds / ONE_HOUR;
            return `just over ${timeAgoInHours.toFixed(0)} hours ago`;
        }
        
        if (deltaInSeconds > ONE_HOUR) {
            const timeAgoInHours = deltaInSeconds / ONE_HOUR;
            return `about ${timeAgoInHours.toFixed(0)} hours ago`;
        }

        if (deltaInSeconds > ONE_MINUTE) {
            const timeAgoInMinutes = deltaInSeconds / ONE_MINUTE;
            return `about ${timeAgoInMinutes.toFixed(0)} minutes ago`;
        }

        return `about ${deltaInSeconds.toFixed(0)} seconds ago`;
    }

    const _getPosName = (posLabel: string): string => {
        switch (posLabel) {
            case Pos.IQMETRIX:
                return 'Cova';
            case Pos.GLOBALTILL:
                return 'Global Till';
            case Pos.GREENLINE:
                return 'Greenline';
            case Pos.PROFITEK:
                return 'Profitek';
            default: 
                return posLabel;
        }
    }

    const _handleAcknowledgeNewOrdersClicked = () => {
        audio.pause();
        audio.currentTime = 0;

        const newOrders: IncomingOrder[] = [];

        // set all the active orders as known
        orders.forEach((o: IncomingOrder) => {
            newOrders.push({
                ...o,
                isNew: false,
            });
        });

        // record known orders
        localStorage.setItem(KNOWN_ITEMS_KEY, newOrders.map((n: IncomingOrder) => n.id).join(','));

        setOrders(newOrders);
        setIsNewOrders(false);
    }

    return (
        <div className="Order-list">
            <ul>
            {
                orders.length > 0 ?
                orders.map((o: IncomingOrder) => {
                    // TODO: make an Order Component
                    return (<li key={`${o.orderId}-${o.orderDate}`} className={o.isNew ? 'new-item' : 'old-item' }>
                        { o.orderId } - {o.firstName} - { o.isWebOrder ? 'Online' : 'In Store'} - {o.isDelivery ? 'DELIVERY' : 'IN-STORE PICKUP'} - {_relativeTime(o.orderDate)} { o.isNew && <strong>NEW!</strong> } { o.transactionId || '' }
                    </li>)
                })
                : <div 
                    className="No-orders"
                    >
                        Nothing here. Looks like you’re all caught up!
                    </div>
            }
            </ul>
            {
                isNewOrders && <div 
                    className="Button-active"
                    onClick={_handleAcknowledgeNewOrdersClicked}
                >
                    <strong>OKAY, I’LL REFRESH {_getPosName(prop.store.pos)}</strong>
                </div>
            }
            {
                !isNewOrders && orders.length > 0 && 
                <div 
                    className="Button-passive"
                    onClick={() => {}}
                >
                    <strong>Looks like you're all caught up.</strong>
                </div>
            }
        </div>
    );
}

export default OrderList;