import React, { useState } from 'react';
import { Pos } from '../types/pos';
import { Store, StoreByClientName, StoreByCompanyAndLocationId } from '../models/store';

interface ISignInProps {
    onSetLocation: (store: Store) => void;
}

function api<T>(url: string, body: any): Promise<T> {
    let formBody = [];
    for (var property in body) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(body[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    const formBodyString = formBody.join("&");

    return fetch(url, {
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: formBodyString,
    }).then((response: any) => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        return response.json() as Promise<T>
      });
  }


function SignIn(prop: ISignInProps) {
    const [passCode, setPassCode] = useState<string>('');
    const [isInvalidLocation, setIsInvalidLocation] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const _fetchLocation = () => {
        setIsFetching(true);

        api('https://tools.budguide.io:3000/services/json', {
            method: 'views.get',
            view_name: 'tablet_all_profile_view_for_app',
        }).then((res: any) => {
            setIsFetching(false);
            setIsInvalidLocation(false);

            const profiles: any[] = res['#data'];
            const thisProfile = profiles
                .find((r: any) => r['field_tablet_passcode'][0].value.toLowerCase() === passCode.toLowerCase());

            if (thisProfile) {
                const pos = thisProfile['field_tablet_pos'][0].value;
                const title = thisProfile['title'];
                let locationId;
                let companyId;
                let clientName;

                let store: Store;

                switch (pos) {
                    case Pos.IQMETRIX:
                        locationId = thisProfile['field_tablet_iqmetrixlocationid'][0].value;
                        companyId = thisProfile['field_tablet_iqmetrixclientname'][0].value;

                        store = new StoreByCompanyAndLocationId(title, pos, companyId, locationId);

                        break;
                    case Pos.GREENLINE:
                        locationId = thisProfile['field_tablet_greenlinelocationid'][0].value;
                        companyId = thisProfile['field_tablet_greenlinecompanyid'][0].value;

                        store = new StoreByCompanyAndLocationId(title, pos, companyId, locationId);
                        break;
                    case Pos.PROFITEK:
                        clientName = thisProfile['field_tablet_profitekclientname'][0].value;

                        store = new StoreByClientName(title, pos, clientName);
                        break;
                    case Pos.GLOBALTILL:
                    default:
                        throw Error('Unsupported Point of Sale');
                }

                if (!store){
                    throw Error('Insufficient data');
                }
                
                prop.onSetLocation(store);
            } else {
                setIsInvalidLocation(true);
            }
        }).catch((err) => {
            console.error(err);
            setIsFetching(false);
            setIsInvalidLocation(true);
        });
    }

    return (
        <div className='sign-in'>
            <h2>You are not signed in.</h2>
            <input
                id='location_id_textfield'
                placeholder='BudGuide Code'
                onKeyUp={(e) => setPassCode(e.currentTarget.value)}
            />

            { 
                isInvalidLocation && <h4>That's not a valid location</h4>
            }
            <button 
                className="Signin-button"
                disabled={isFetching}
                onClick={_fetchLocation}
            >
                Sign In
            </button>
        </div>
    )
}

export default SignIn;