abstract class Store {
    title: string;
    pos: string;

    constructor(title: string, pos: string) {
        this.title = title;
        this.pos = pos;
    }
}

class StoreByClientName extends Store{
    clientName: string;

    constructor(title: string, pos: string, clientName: string){
        super(title, pos);
        this.clientName = clientName;
    }

    getRootPath(): string{
        return `${this.pos.toLowerCase()}/orderHistory/${this.clientName}`;
    }

    getJsonString(): string{
        let data = {
            title: this.title,
            pos: this.pos,
            clientName: this.clientName,
        };

        return JSON.stringify(data);
    }

    static fromJsonString(data: string): Store | undefined {
        let obj = JSON.parse(data);
        let pos: string = obj.pos;
        let title: string = obj.title;
        let clientName: string = obj.clientName;

        if(pos && title && clientName){
            return new StoreByClientName(title, pos, clientName);
        }
    }
}

class StoreByCompanyAndLocationId extends Store{
    companyId: string;
    locationId: number;

    constructor(title: string, pos: string, companyId: string, locationId: number){
        super(title, pos);
        this.companyId = companyId;
        this.locationId = locationId;
    }

    getRootPath(): string{
        return `${this.pos.toLowerCase()}/orderHistory/${this.companyId}/${this.locationId}`;
    }

    getJsonString(): string{
        let data = {
            title: this.title,
            pos: this.pos,
            companyId: this.companyId,
            locationId: this.locationId
        };

        return JSON.stringify(data);
    }

    static fromJsonString(data: string): Store | undefined {
        let obj = JSON.parse(data);
        let pos: string = obj.pos;
        let title: string = obj.title;
        let companyId: string = obj.companyId;
        let locationId: number = obj.locationId;

        if(pos && title && companyId && locationId){
            return new StoreByCompanyAndLocationId(title, pos, companyId, locationId);
        }
    }
}

export {Store, StoreByClientName, StoreByCompanyAndLocationId}