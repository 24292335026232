import React, { useEffect, useState } from 'react';

import './App.css';
import OrderList from './components/OrderList';
import SignIn from './components/SignIn';
import { Store, StoreByClientName, StoreByCompanyAndLocationId } from './models/store';

const STORE_KEY = 'io.budguide.notifications.store';

function App() {
    const [store, setStore] = useState<Store>();

    useEffect(() => {
        const storedStoreData = localStorage.getItem(STORE_KEY);

        if (storedStoreData) {
            let storedStoreByClientName = StoreByClientName.fromJsonString(storedStoreData);
            let storedStoreByCompanyAndLocationId = StoreByCompanyAndLocationId.fromJsonString(storedStoreData);
            let storedStore = storedStoreByClientName ?? storedStoreByCompanyAndLocationId;

            if(storedStore){
                setStore(storedStore);
            }
        }
    }, []);

    const _handleSetLocation = (store: Store) => {
        setStore(store);

        let storeData = (store as StoreByClientName)?.getJsonString() ?? (store as StoreByCompanyAndLocationId)?.getJsonString() ?? '';
        localStorage.setItem(STORE_KEY, storeData);
    }

    const _handleLogout = () => {
        setStore(undefined);
        localStorage.removeItem(STORE_KEY);
    }

    return (
        <div className="App">
            <div className="App-header">
                { 
                    store && 
                    <div className="logout">
                        <button className="Button-logout" onClick={_handleLogout}>Logout?</button>
                    </div>
                }
                {
                    store?.title &&
                    <div className="location-name">
                        { store?.title }
                    </div>
                }
            </div>
            {
                store != null
                ? <OrderList
                    store={store}
                />
                : <SignIn onSetLocation={_handleSetLocation} />
            }
        </div>
    );
}

export default App;
